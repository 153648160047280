import React, { useState, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import appVars from '../../../appVars.json';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Card, CardContent, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(1),
    },
    chip: {
        margin: 2,
        overflow: 'hidden'
    },
}));

const AdAccounts = (props) => {
    const classes = useStyles();
    const [selectedOptions, setSelectedOptions] = useState(() => {
        // Check if defaultValue is a string and contains commas (CSV string)
        if (typeof props.defaultValue === 'string' && props.defaultValue.includes(',')) {
            return props.defaultValue.split(',').map(value => value.trim());
        }
        // Check if defaultValue is defined and not an empty string, then wrap in an array
        // This will handle single numbers, single strings without commas, and non-empty strings
        else if (props.defaultValue || typeof props.defaultValue === 'number') {
            return [`${props.defaultValue}`]; // Convert to string to ensure consistency
        }
        // Return an empty array if defaultValue is undefined, null, or empty
        return [];
    });


    const [options, setOptions] = useState();



    async function fetchData() {
        let type = '';
        let url = appVars.api_url;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify({ token: props.saKey })
        };

        switch (props.name) {
            case 'google_paid':
                url += "/google/accountids/get";
                type = 'Paid';
                break;
            case 'google_grant':
                url += "/google/accountids/get";
                type = 'Grant';
                break;
            case 'meta':
                url += "/meta/ids";
                break;
            case 'bing':
                url += "/bing/accounts";
                break;
            case 'stackadapt':
                url += "/stackadapt/api/ga";
                break;
            default:
                url = ""
                break;
        }

        if (url === "") {
            return false;
        }
        try {
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            const filteredData = type ? _.filter(data, (row) => _.includes(row.ACCOUNT_NAME, type)) : data;
            setOptions(filteredData);
        } catch (error) {
            console.error('error', error);
        }
    }

    useEffect(() => {
        if (!options) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [props, options]);

    return (
        <div className={classes.formControl}>

            <Card >
       
                <CardContent>
                    {(options ?
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            disabled={(props.admintoken >= 6 ? false : true)}
                            options={options}
                            getOptionLabel={(option) => `${option.ACCOUNT_NAME} - ${option.ACCOUNT_ID}`}
                            defaultValue={selectedOptions.map((option) => options.find((o) => o.ACCOUNT_ID.toString() === option) || {})}
                            data-tab={props.tab}
                            onChange={(e, newValue) => {
                                const reportReturn = Object.keys(newValue).map((key, index) => (
                                    newValue[index].ACCOUNT_ID
                                ));
                                e.stopPropagation();
                                setSelectedOptions(reportReturn);
                                if (props.onSelectionChange) {
                                    props.onSelectionChange(reportReturn, props.tab, props.label);
                                }
                                // console.log(props.tab);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip data-tab={props.tab} label={option.ACCOUNT_NAME} {...getTagProps({ index })} className={classes.chip} style={{ backgroundColor: props.themeColor.secondary, color: props.themeColor.primary }} key={option.ACCOUNT_ID} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    data-tab={props.tab}
                                    {...params}
                                    variant="outlined"
                                    label={`Select ${props.label}`}

                                />
                            )}
                        />
                        : <LinearProgress></LinearProgress>
                    )}
                </CardContent>
            </Card>


        </div>
    );
};

export default AdAccounts;
