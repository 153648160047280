import { Box, Card, CardActionArea, CardActions, CardContent, CardHeader, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ReactDOMServer from 'react-dom/server';
import { ArrowDownwardOutlined, ArrowUpwardOutlined, HelpOutline } from '@material-ui/icons';
import { NumericFormat } from 'react-number-format';
import { lightenHexColor, darkenHexColor } from '../../dwi-functions';
import Chart from 'react-apexcharts'
var CurrencyFormat = require('react-currency-format');



const ChartCard = (props) => {

    // console.log(props.data);






    var options = {
        chart: {
            width: "auto",
            parentHeightOffset: 0,
            type: "area",
            toolbar: false
        },
        tooltip: {
            x: {
                show: false
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const value = series?.[seriesIndex]?.[dataPointIndex] ?? 0;
            
                // Safely access the previousdata series or default to 0
                const previousSeries = props.previousdata?.series ?? [{ y: 0 }];
                const valueP = previousSeries?.[dataPointIndex]?.y ?? 0;
            
                let countWrite = <NumericFormat decimalScale={0} value={value} displayType={'text'} thousandSeparator={true} />;
                let countWriteP = <NumericFormat decimalScale={0} value={valueP} displayType={'text'} thousandSeparator={true} />;
            
                switch (props.title) {
                    case 'Impressions':
                    case 'Clicks':
                        countWrite = <NumericFormat decimalScale={0} value={value} displayType={'text'} thousandSeparator={true} />;
                        countWriteP = <NumericFormat decimalScale={0} value={valueP} displayType={'text'} thousandSeparator={true} />;
                        break;
            
                    case 'COST':
                    case 'Revenue':
                    case 'Avg Gift':
                    case 'CPC':
                    case 'CPM':
                        countWrite = (
                            <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={value}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />
                        );
                        countWriteP = (
                            <CurrencyFormat
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={valueP}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />
                        );
                        break;
            
                    case 'CTR':
                    case 'Conversion Rate':
                        countWrite = <NumericFormat value={value.toFixed(2)} displayType="text" suffix="%" />;
                        countWriteP = <NumericFormat value={valueP.toFixed(2)} displayType="text" suffix="%" />;
                        break;
            
                    default:
                        countWrite = <NumericFormat decimalScale={0} value={value} displayType={'text'} thousandSeparator={true} />;
                        countWriteP = <NumericFormat decimalScale={0} value={valueP} displayType={'text'} thousandSeparator={true} />;
                        break;
                }
            
                return ReactDOMServer.renderToString(
                    <Box border={1} borderColor={props.color.secondary} bgcolor={lightenHexColor(props.color.secondary, 35)} p={2} pt={1} pb={1}>
                        <Typography variant="h6">{w.config.series[0]?.data[dataPointIndex]?.x}</Typography>
                        <Typography variant="subtitle1">Current: {countWrite}</Typography>
                        <Typography variant="subtitle1">Previous: {countWriteP}</Typography>
                    </Box>
                );
            }
            
        },
        fill: {
            colors: [props.color.secondary, darkenHexColor(props.color.secondary, 50)]
        },
        zoom:{
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 1,
            colors: [darkenHexColor(props.color.secondary, 25)]
        },
        grid: {
            show: false,
            padding: {
                left: -10,
                right: 0,
                bottom: 0,
                top: 0
            }
        },
        dataLabels: {
            enabled: false
        },
        series: [
            {
                name: "Current",
                data: props.data.series
            }
        ],
        yaxis: {
            labels: {
                show: false,
            }
        },
        xaxis: {
            type: "datetime",
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: false
            },
            labels: {
                floating: true,
                show: false
            }
        }
    };






    const theme = createTheme({
        palette: {
            primary: {
                main: darkenHexColor(props.color.primary, 0), // Assuming darkenHexColor returns a valid color
            },
            secondary: {
                main: darkenHexColor(props.color.secondary, 0), // Assuming the same
            },
        },
        overrides: {
            MuiTooltip: {
                tooltip: {
                    backgroundColor: lightenHexColor("#000000", 0),
                    color: "#fff",
                    fontSize: '.75rem',
                    border: '1px solid ' + lightenHexColor(props.color.secondary, 0)
                },
                arrow: {
                    color: lightenHexColor("#000000", 0),
                }
            }
        }
    });






    //  console.log(props);
    var countWrite = null;


    switch (props.title) {
        case 'COST':
            countWrite = <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={(props.data.count !== null ? props.data.count : 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />;

            break;
        case 'Revenue':
            countWrite = <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={(props.data.count !== null ? props.data.count : 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />;

            break;
        case 'Avg Gift':
            countWrite = <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={(props.data.count !== null ? props.data.count : 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />;

            break;
        case 'CTR':
            countWrite = <NumericFormat value={(props.data.count !== null ? (props.data.count).toFixed(2) : 0)} displayType="text" suffix="%" />

            break;
        case 'Conversion Rate':
            countWrite = <NumericFormat value={(props.data.count !== null ? (props.data.count).toFixed(2) : 0)} displayType="text" suffix="%" />

            break;
        case 'CPC':
            countWrite = <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={(props.data.count !== null ? props.data.count : 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />;

            break;
        case 'CPM':
            countWrite = <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={(props.data.count !== null ? props.data.count : 0)} displayType={'text'} thousandSeparator={true} prefix={'$'} />;

            break;
        default:
            countWrite = <NumericFormat decimalScale={0} value={(props.data.count !== null ? props.data.count : 0)} displayType={'text'} thousandSeparator={true} />

            break;
    }






    return (
        <div>
            <ThemeProvider theme={theme}>

                {(props ?


                    <Card elevation={0} style={{ border: '1px solid ' + lightenHexColor(props.color.secondary, 0) }}>
                        <CardHeader
                            subheader={props.title}
                            title={
                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                    <div style={{ flexGrow: '1' }}>{countWrite}</div>
                                    <Tooltip title={props.explain} arrow>
                                        <HelpOutline style={{ fontSize: '16px' }} />
                                    </Tooltip>
                                </div>
                            }
                            subheaderTypographyProps={{ style: { color: lightenHexColor(props.color.primary, 0), marginLeft: theme.spacing(1) } }}
                            style={{ padding: theme.spacing(1), backgroundColor: lightenHexColor(props.color.secondary, 0), color: lightenHexColor(props.color.primary, 0) }}
                        ></CardHeader>

                        <CardContent
                            style={{ padding: 0, paddingTop: theme.spacing(0) }}
                        >
                            <Chart type='area' series={options.series} options={options} />
                        </CardContent>
                        <CardActionArea>
                            <CardActions style={{ padding: theme.spacing(1), backgroundColor: lightenHexColor(props.color.primary, 25), color: lightenHexColor(props.color.secondary, 0) }} >
                                <div><NumericFormat decimalScale={2} value={props.delta} displayType="text" suffix="%" /> {(props.delta < 0 ? <ArrowDownwardOutlined color="error" ></ArrowDownwardOutlined> : <ArrowUpwardOutlined style={{ color: "green" }} ></ArrowUpwardOutlined>)}</div>

                            </CardActions>
                        </CardActionArea>
                    </Card>
                    : '')}
            </ThemeProvider>
        </div>
    );
};

ChartCard.propTypes = {

};

export default ChartCard;
