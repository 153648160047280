import React, { useState, useEffect } from "react";
import { style } from "./index.scss";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Button,
    Snackbar

} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ClipboardJS from "clipboard";
import appVars from '../../appVars.json';
import _ from 'lodash';
import Prismjs from "prismjs";
import themeFile from '../../theme.json';
import moment from 'moment';



const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    table: {
        // marginTop: "1em",
    },
    title: {
        borderBottom: "1px solid #000",
    },
    pos: {
        marginBottom: 12,
    },
    pre: {
        whiteSpace: "normal",
        margin: '1em'
    },
    paperError: {
        background: themeFile.palette.feedback.error.background,
        textAlign: "center",
        marginBottom: "1em",
        padding: ".5em"
    },
    paperSuccess: {
        background: themeFile.palette.feedback.success.background,
        textAlign: "center",
        marginBottom: "1em",
        padding: ".5em"
    }
});

var btn = new ClipboardJS(".copy");
const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
const id = getLastItem(window.location.href);

const Conversions = (props) => {

    const classes = useStyles();
    const [data, setData] = useState(props);

    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState([true]);
    const [copySuccess, setCopySuccess] = useState("");
    const [conversionsFailed, setConverionsFailed] = useState([]);
    const [failed, setFailed] = useState("0");
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors(false);
        setCopySuccessShow(false);
    };

    async function fetchData() {
        const res = await fetch(appVars.api_url + "/conversion/get/" + id);
        res
            .json()
            .then((res) => {
                //                console.log(res);
                var d = _.filter(res, function (o) {
                    var e = moment(o.createdAt).format("YYYY-MM-DD");
                    //  console.log(e);
                    if (e >= data.sdate && e <= data.edate) {
                        return o.createdAt;
                    }
                });



                setUsers(d);
                // console.log(appVars.api_url + "/conversion/get/" + id);
                setLoading(false);
            })
            .catch((err) => setErrors(true));

        const res2 = await fetch(appVars.api_url + "/conversions/failed/" + id);
        res2
            .json()
            .then((res2) => {
                setConverionsFailed(res2);
                setFailed(res2.length)
                // console.log(res2);
                setLoading(false);
            })
            .catch((err) => setErrors(true));



    }


    useEffect(() => {
        setData(props);

        var clipboard = new ClipboardJS(".copy");
        clipboard.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });

        fetchData();
        Prismjs.highlightAll();
        const interval = setInterval(() => {
            fetchData();
        }, 60000);
        return () => clearInterval(interval);
    });

    return (
        <div className={style}>
            <div className={classes.table}>

                <Grid container spacing={3}>
                    <Grid item xl={6} md={6}>

                        <MaterialTable
                            style={{ boxShadow: 'none', border: '1px solid #ccc', marginTop: '1em' }}

                            isLoading={loading}
                            columns={[
                                { title: "pkRecID", field: "pkRecId", hidden: false },
                                { title: "Request", field: "requestdata", hidden: true },
                                { title: "Response", field: "responsedata", hidden: true },
                                { title: "Entry Date", field: "createdAt", type: "datetime" }
                            ]}
                            detailPanel={[
                                {
                                    tooltip: 'Show Request',
                                    icon: 'upload',
                                    render: rowData => {
                                        var d = atob(rowData.requestdata);
                                        d = JSON.parse(d);
                                        console.log(d);
                                        return (
                                            <div
                                                style={{
                                                    color: '#fff',
                                                    backgroundColor: '#000',
                                                }}
                                            >
                                                <pre><code className="language-json"> {JSON.stringify(d, null, "\t")}</code></pre>
                                            </div>
                                        )
                                    },
                                }, {
                                    tooltip: 'Show Response',
                                    icon: 'download',
                                    render: rowData => {
                                        return (
                                            <div
                                                style={{
                                                    color: '#fff',
                                                    backgroundColor: '#000',
                                                }}
                                            >
                                                <pre className={classes.pre}
                                                > {atob(rowData.responsedata)}</pre>
                                            </div>
                                        )
                                    },
                                }]}

                            data={users}
                            title="Conversion Events"
                            options={{
                                grouping: false,
                                pageSize: 5,
                                pageSizeOptions: [5, 25, 50, 75, 100, 200],
                                search: false
                            }}
                        />
                    </Grid>
                    <Grid item xl={6} md={6}>
                        <MaterialTable
                            style={{ boxShadow: 'none', border: '1px solid #ccc', marginTop: '1em' }}
                            isLoading={loading}
                            columns={[
                                { title: "pkRecID", field: "pkRecId", hidden: false },
                                { title: "Request", field: "requestdata", hidden: true },
                                { title: "Response", field: "responsedata", hidden: true },
                                { title: "Entry Date", field: "createdAt", type: "datetime" }
                            ]}
                            detailPanel={[
                                {
                                    tooltip: 'Show Request',
                                    icon: 'upload',
                                    render: rowData => {
                                        var d = atob(rowData.requestdata);
                                        d = JSON.parse(d);
                                        console.log(d.data);
                                        return (
                                            <div
                                                style={{
                                                    color: '#fff',
                                                    backgroundColor: '#000',
                                                }}
                                            >
                                                <pre><code className="language-json"> {JSON.stringify(d.data, null, "\t")}</code></pre>
                                            </div>
                                        )
                                    },
                                }, {
                                    tooltip: 'Show Response',
                                    icon: 'download',
                                    render: rowData => {
                                        return (
                                            <div
                                                style={{
                                                    color: '#fff',
                                                    backgroundColor: '#000',
                                                }}
                                            >
                                                <pre className={classes.pre}
                                                > {atob(rowData.responsedata)}</pre>
                                            </div>
                                        )
                                    },
                                }]}

                            data={conversionsFailed}
                            title="Failed Events"
                            options={{
                                grouping: false,
                                pageSize: 5,
                                pageSizeOptions: [5, 25, 50, 75, 100, 200],
                                search: false
                            }}
                        />

                    </Grid>
                </Grid>






            </div>
            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data can not be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>
        </div>

    );
};

Conversions.propTypes = {

};

export default Conversions;
