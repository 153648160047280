import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import appVars from '../../appVars.json';
import { TextField } from '@material-ui/core';




const TinyEditor = (props) => {
    const editorRef = useRef(null);
    const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
    var id = getLastItem(window.location.href);
    const [client, setClient] = useState({
        "pkRecID": "",
        "description": "",
        "client_id": "",
        "settings": {
            "css": "",
            "html": ""
        }
    });
    const [hasError, setErrors] = useState({ status: false });


    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };


    async function fetchData() {



        const res2 = await fetch(appVars.api_url + "/lp/detail/" + id);
        res2
            .json()
            .then((res2) => {
                setClient(res2[0]);
                //  console.log(res2);
                ////console.log(v);
            })

            .catch((err) => {
                setErrors({ status: true, msg: err.message });
                //console.log(err);
            });


    }



    useEffect(() => {
        fetchData();
 
        return () => {

        };
    }, []);





    return (
        <div >
            <TextField value={client.description}></TextField>
            <Editor
                apiKey={appVars.tinyapi}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={client.settings.html}
                content_style={client.settings.css}
                init={{
                    menubar: true,
                    // html:client.settings.html,
                    content_style: client.settings.css,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help |' +
                        'code',
                    //   content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            <a href="#" onClick={log}>Log editor content</a>
        </div>
    );
};

Editor.propTypes = {

};

export default TinyEditor;
