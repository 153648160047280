import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import { Button, Grid, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography, Divider } from '@material-ui/core';
import appVars from '../../appVars.json';
import MaterialTable from 'material-table';
import { MdPreview } from 'react-icons/md';
import { Delete, EditOutlined, FileCopy } from '@material-ui/icons';
import { Add } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Screenshot from '../controls/screenshot'


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '50%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        minWidth: 275,
    },
    table: {
        marginTop: ".5em",
    },
    title: {
        borderBottom: "1px solid #000",
    },
    pos: {
        marginBottom: 12,
    },
    pre: {
        whiteSpace: "normal",
        margin: '1em'
    },
    divider: {
        marginBottom: "1em",
        marginTop: "1em"
    },
}));


const LandingPages = (props) => {
    const classes = useStyles();
    const [landingPages, setlandingPages] = useState([{}]);
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [selectedLP, setSelectedLP] = React.useState({});
    const [props2, setProps] = React.useState({});


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    async function fetchData() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const res = await fetch(appVars.api_url + "/lp/" + props.clientId, requestOptions);
        res
            .json()
            .then((response) => {
                setlandingPages(response);
            })
            .catch(error => console.log('error', error));
    }



    useEffect(() => {
        fetchData();
        // console.log(landingPages);
        return () => {

        };
    }, []);



    return (
        <div className={style}>

            <Button size='small' startIcon={<Add></Add>} variant="outlined" color="primary" onClick={handleClickOpen} >Add Landing Page</Button>

            <Divider className={classes.divider}></Divider>


            {(landingPages.length <= 0
                ? <Typography>No Landing Pages</Typography>
                :
                <MaterialTable
                    style={{ boxShadow: 'none', border: '1px solid #ccc' }}
                    options={{ showTitle: false, search: false, paging: false }}
                    data={landingPages}
                    columns={[
                        {
                            title: "", field: "description", editable: "never", render: rowData => <div>
                                <IconButton color="primary" target="_blank" href={rowData.landing_url}><MdPreview></MdPreview>
                                </IconButton><IconButton color="primary" target="_blank" onClick={(e) => {
                                    var myHeaders = new Headers();
                                    myHeaders.append("Content-Type", "application/json");
                                    var raw = {
                                        "pkRecID": '1',
                                        "client_id": props.clientId,
                                        "landing_url": rowData.landing_url,
                                        "description": rowData.description+'(Duplicate)',
                                        "settings": JSON.stringify(rowData.settings)
                                    };
                                     console.log(raw);
                                    // window.location.reload();
                                    var requestOptions = {
                                        method: 'POST',
                                        headers: myHeaders,
                                        body: JSON.stringify(raw),
                                        redirect: 'follow'
                                    };

                                    fetch(appVars.api_url + "/lp/add", requestOptions)
                                        .then((response) => {
                                          fetchData();
                                        })
                                        .then(result => console.log(result))
                                        .catch(error => console.log('error', error));


                                }}><FileCopy></FileCopy>
                                </IconButton>
                                <IconButton target='_BLANK' color="secondary" href={appVars.editor + 'index.php/?clid=' + rowData.client_id + '&lpid=' + rowData.pkRecID}><EditOutlined></EditOutlined></IconButton>
                                <IconButton color="primary" target="_blank" onClick={(e) => {
                                    setSelectedLP(rowData);
                                    setOpenDelete(true);
                                    //  console.log(userConfirmed);
                                }}><Delete></Delete>
                                </IconButton>
                            </div>
                        },
                        { title: "Name", field: "description", editable: "always" },
                        { title: "Page URL", field: "landing_url", editable: "always" },
                        { title: "Create Date", field: "createdAt", type: "datetime", editable: "never" },
                        { title: "Last Update", field: "updatedAt", type: "datetime", editable: "never" }
                    ]}
                    cellEditable={{
                        cellStyle: {},
                        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                            return new Promise((resolve, reject) => {
                                var myHeaders = new Headers();
                                myHeaders.append("Content-Type", "application/json");
                                var raw = {
                                    "pkRecID": rowData.pkRecID
                                };
                                raw[columnDef.field] = newValue;
                                rowData[columnDef.field] = newValue;
                                var requestOptions = {
                                    method: 'POST',
                                    headers: myHeaders,
                                    body: JSON.stringify(raw),
                                    redirect: 'follow'
                                };
                                fetch(appVars.api_url + "/lp/add", requestOptions)
                                    .then((response) => {
                                        setlandingPages(rowData);
                                        resolve();
                                        response.text();
                                    })
                                    .then(result => console.log(result))
                                    .catch(error => console.log('error', error));
                            });
                        }
                    }}
                />

            )}




            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create new landing page</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To create a new landing page give it a name and put in the landing page url.
                    </DialogContentText>

                    <TextField
                        autoFocus
                        size='small'
                        margin="dense"
                        id="description"
                        label="Page Name"
                        type="text"
                        variant="outlined"
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="landing_url"
                        label="Page URL"
                        type="text"
                        variant="outlined"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button onClick={(e) => {
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        var raw = {
                            "pkRecID": '1',
                            "client_id": props.clientId,
                            "landing_url": document.getElementById('landing_url').value,
                            "description": document.getElementById('description').value,
                            "settings": "{}"
                        };
                        // console.log(raw);
                        // window.location.reload();
                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: JSON.stringify(raw),
                            redirect: 'follow'
                        };

                        fetch(appVars.api_url + "/lp/add", requestOptions)
                            .then((response) => {
                               fetchData();
                            })
                            .then(result => console.log(result))
                            .catch(error => console.log('error', error));
                    }}
                        color="primary"
                        variant="contained"
                    >
                        Add new
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={openDelete}

            >
                <DialogTitle id="confirmation-dialog-title">Do you want to delete landing page?</DialogTitle>
                <DialogContent dividers>
                    <Typography variant='body'>{selectedLP.description}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        setOpenDelete(false);
                    }} autoFocus color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(e) => {
                        console.log('delete');

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                            "pkRecID": selectedLP.pkRecID
                        });

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };
                        fetch(appVars.api_url + "/lp/delete", requestOptions)
                            .then(response => response.text())
                            .then(result => setOpenDelete(false))
                            .catch(error => console.log('error', error));




                    }} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>




        </div>
    );
};

LandingPages.propTypes = {

};

export default LandingPages;
