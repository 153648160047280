import React, { useState, useEffect } from "react";
import { style } from "./index.scss";
import MaterialTable from "material-table";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  DialogContentText,
  DialogContent,
  Button,
  Divider,
  Dialog,
  IconButton,
  Toolbar,
  AppBar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import ClipboardJS from "clipboard";
import appVars from '../appVars.json';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ImageDetails from "./image-details";
import { v4 as uuidv4 } from 'uuid';
import { ImageOutlined } from "@material-ui/icons";

const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
var id = getLastItem(window.location.href);

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  head: {
    borderBottom: "1px solid"
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.light
  },
  thumbnail: {
    maxWidth: "150px;"
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  divider: {
    marginBottom: "1em",
    marginTop: "1em"
  },
}));



const Timers = (props) => {
  const classes = useStyles();
  const [hasError, setErrors] = useState(false);
  const [copySuccessShow, setCopySuccessShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState([true]);
  const [modalOpen, setOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [newid, setUUID] = useState(uuidv4());

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setErrors(false);
      setCopySuccessShow(false);
      setOpen(false);
      return;
    }
    setErrors(false);
    setCopySuccessShow(false);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  async function fetchData() {
    const res = await fetch(appVars.api_url + "/timers/" + props.client);
    res
      .json()
      .then((res) => {
        setUsers(res);
        console.log(res);
      })
      .then((res) => setLoading(false))
      .catch((err) => setErrors(true));
  }

  useEffect(() => {
    fetchData();

    var clipboard = new ClipboardJS(".copy");
    clipboard.on("success", function (e) {
      setCopySuccess(e.action + ": " + e.text);
      setCopySuccessShow(true);
      e.clearSelection();
    });
  }, []);
  return (
    <div className={style}>
      <Button startIcon={<ImageOutlined></ImageOutlined>} variant="outlined" color="primary" onClick={handleClickOpen}>
        Add Image
      </Button>
      <Divider className={classes.divider}></Divider>
      <MaterialTable
        isLoading={loading}
        columns={[
          { title: "pkRecId", field: "pkRecId", hidden: true },
          { title: "Timer Name", field: "timerDescription" },
          {
            title: "Thumbnail", field: "", render: (rowData) => (
              <Link href={'https://api.digitalwerksinnovations.com/igen/imager.php?ID=' + rowData.pkRecId + '&msg=[First Name]'} target="_BLANK"><img alt="" className={classes.thumbnail} src={'https://api.digitalwerksinnovations.com/igen/imager.php?msg=Test Text&ID=' + rowData.pkRecId} /></Link>
            )
          },
          {
            title: "Javascript Generator", field: "", render: (rowData) => (
              <Link href={'http://localhost:7325/imager/' + rowData.pkRecId + '/?m=[First Name]'} target="_BLANK"><img alt="" className={classes.thumbnail} src={'http://localhost:7325/imager/' + rowData.pkRecId + '/?m=[First Name]'} /></Link>
            )
          },
          {
            title: "Debug", field: "", render: (rowData) => (
              <Link href={'https://api.digitalwerksinnovations.com/igen/imager.php?DEBUG=1&msg=Sample Name&ID=' + rowData.pkRecId} target="_BLANK">Debug</Link>
            )
          },
          { title: "X Offset", field: "xOffset" },
          { title: "Y Offset", field: "yOffset" },
          { title: "Text Align", field: "textAlign" },
          { title: "Text Color", field: "rgbColor" },

        ]}
        data={users}
        title="Timers"
        options={{
          grouping: false,
          showTitle:false,
          search: false,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 75, 100, 200]
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                /* setData([...data, newData]); */
                resolve();
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve();
              }, 1000);
            }),
        }}
      />



      <Dialog open={modalOpen} fullScreen onClose={handleClose} TransitionComponent={Transition} aria-labelledby="form-dialog-title">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Create a New Image
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            <ImageDetails clientID={id} ></ImageDetails>
          </DialogContentText>
        </DialogContent>

      </Dialog>








    </div>
  );
};

Timers.propTypes = {

};

export default Timers;
