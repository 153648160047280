import React, { useState, useEffect } from "react";
import { style } from "./index.scss";
import MaterialTable from "material-table";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    Typography,
    Button,
    Snackbar,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Divider

} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import ClipboardJS from "clipboard";
import appVars from '../../appVars.json';
import { Add } from "@material-ui/icons";
import themeFile from '../../theme.json';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '50%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        minWidth: 275,
    },
    table: {
        marginTop: ".5em",
    },
    title: {
        borderBottom: "1px solid #000",
    },
    pos: {
        marginBottom: 12,
    },
    pre: {
        whiteSpace: "normal",
        margin: '1em'
    },
    divider: {
        marginBottom: "1em",
        marginTop: "1em"
    },
}));

var btn = new ClipboardJS(".copy");
const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
const id = getLastItem(window.location.href);

const Elements = (props) => {

    const classes = useStyles();
    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [Elements, setElements] = useState([]);
    const [html, setHTML] = useState('');
    const [loading, setLoading] = useState([true]);
    const [copySuccess, setCopySuccess] = useState("");
    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    async function fetchData() {
        const res = await fetch(appVars.api_url + "/elements/" + id);
        res
            .json()
            .then((res) => {
                setElements(res);
                console.log(appVars.api_url + "/conversion/get/" + id);
                setLoading(false);
            })
            .catch((err) => setErrors(true));
    }

    useEffect(() => {
        fetchData();

        var clipboard = new ClipboardJS(".copy");
        clipboard.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });
    }, []);
    return (
        <div className={style}>
            <div className={classes.table}>

                <Button size="small" variant="outlined" startIcon={<Add></Add>} color="primary" onClick={handleClickOpen} >Add New</Button>
                <Divider className={classes.divider}></Divider>

                {(Elements <= 0
                    ? <Typography>No Editor Elements</Typography>
                    :
                    <MaterialTable
                        isLoading={loading}
                        columns={[
                            { title: "pkRecID", field: "pkRecId", hidden: true },
                            { title: "ElementType", field: "elementType", hidden: true },
                            { title: "Settings", field: "elementSettings", hidden: true },
                            {
                                title: "Name", field: "elementSettings", editable: "always", sorting: true, render: rowData => {
                                    return (
                                        <div dangerouslySetInnerHTML={{ __html: rowData.elementSettings.block.label }} />
                                    )
                                }
                            }, {
                                title: "Block", field: "elementSettings", editable: "never", render: rowData => {
                                    return (
                                        <div dangerouslySetInnerHTML={{ __html: rowData.elementSettings.block.media }} />
                                    )
                                }
                            }
                            ,
                            { title: "Entry Date", field: "createdAt", type: "datetime" }
                        ]}
                        data={Elements}
                        options={{ showTitle: false, search: false, paging: false }}
                        cellEditable={{
                            cellStyle: {},
                            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                return new Promise((resolve, reject) => {
                                    var myHeaders = new Headers();
                                    myHeaders.append("Content-Type", "application/json");
                                    var raw = {
                                        "pkRecID": rowData.pkRecID
                                    };
                                    raw[columnDef.field] = newValue;
                                    rowData[columnDef.field] = newValue;
                                    var requestOptions = {
                                        method: 'POST',
                                        headers: myHeaders,
                                        body: JSON.stringify(raw),
                                        redirect: 'follow'
                                    };
                                    fetch(appVars.api_url + "/lp/add", requestOptions)
                                        .then((response) => {
                                            setElements(rowData);
                                            resolve();
                                            response.text();
                                        })
                                        .then(result => console.log(result))
                                        .catch(error => console.log('error', error));
                                });
                            }
                        }}
                    />
                )}

            </div>



            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create new HTML element</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To create a new element give it a name and add HTML and CSS.
                    </DialogContentText>

                    <TextField
                        autoFocus
                        margin="normal"
                        id="id"
                        label="Element ID"
                        type="text"
                        variant="outlined"
                        fullWidth
                        style={{ width: '49%', marginRight: '1%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField

                        margin="normal"
                        id="label"
                        label="Element Label"
                        type="text"
                        variant="outlined"
                        fullWidth
                        style={{ width: '50%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <FormControl style={{ width: '100%', marginRight: '0' }}>
                        <InputLabel shrink={true} style={{ paddingLeft: '15px' }} id="tageName-label">Outer Tag</InputLabel>
                        <Select
                            labelId="tagName-label"
                            id="tagName"
                            variant="outlined"
                        >
                            <MenuItem value="div">&lt;div&gt;</MenuItem>
                            <MenuItem value="section">&lt;section&gt;</MenuItem>
                            <MenuItem value="span">&lt;span&gt;</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField

                        margin="normal"
                        id="html"
                        label="HTML"
                        type="text"
                        onChange={(e) => {
                            document.getElementById('display-html').innerHTML = e.currentTarget.value + '<style>' + document.getElementById('styles').value + '</style>';
                        }}
                        variant="outlined"
                        multiline
                        minRows={4}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '49%', marginRight: '1%' }}
                    />
                    <TextField

                        margin="normal"
                        id="styles"
                        label="STYLES"
                        type="text"
                        onChange={(e) => {
                            document.getElementById('display-html').innerHTML = document.getElementById('html').value + '<style>' + e.currentTarget.value + '</style>';
                        }}
                        variant="outlined"
                        multiline
                        minRows={4}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '50%' }}
                    />


                    <div id="display-html"></div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button onClick={(e) => {
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        var settings = {
                            "block": {
                                "id": document.getElementById("id").value,
                                "label": document.getElementById("label").value,
                                "media": "",
                                "content": {
                                    "type": document.getElementById("id").value
                                },
                                "attributes": {
                                    "class": "gjs-block-section"
                                }
                            },
                            "component": {
                                "model": {
                                    "defaults": {
                                        "styles": document.getElementById("styles").value,
                                        "tagName": document.getElementById("tagName").value,
                                        "attributes": {
                                            "class": document.getElementById("id").value
                                        },
                                        "components": document.getElementById("html").value
                                    }
                                }
                            }
                        };






                        var raw = {
                            "pkRecId": '1',
                            "clientId": props.clientID,
                            "elementType": 'a30b7380-5a2a-11ee-b869-16725369f13b',
                            "elementSettings": JSON.stringify(settings)
                        };
                        console.log(raw);

                        window.location.reload();


                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: JSON.stringify(raw),
                            redirect: 'follow'
                        };

                        fetch(appVars.api_url2 + "/elements/add", requestOptions)
                            .then((response) => {
                                window.location.reload();
                            })
                            .then(result => console.log(result))
                            .catch(error => console.log('error', error));




                    }}
                        color="primary"
                        variant="contained"
                    >
                        Add new
                    </Button>
                </DialogActions>
            </Dialog>


            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data can not be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>
        </div>

    );
};

Elements.propTypes = {

};

export default Elements;
