import React, { useState, useEffect } from 'react';
import { style } from './index.scss';
import MaterialTable from 'material-table'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Typography, Card, CardActionArea, Button, CardContent, CardMedia, CardActions, Link, Grid, List, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import appVars from '../appVars.json';
import Image from 'material-ui-image';


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  table: {
    marginTop: '1em'
  },
  title: {
    borderBottom: '1px solid #000',
  },
  pos: {
    marginBottom: 12,
  },
  clientLogo: {
    maxWidth: '100px'
  }
});

const Clients = ({ }) => {
  const classes = useStyles();
  const [hasError, setErrors] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState([true]);


  const tableoptions = {
    pageSize: 10,
    search: false,
    header: false,

  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrors(false);
  };

  async function fetchData() {
    const res = await fetch(appVars.api_url + "/clients");
    res
      .json()
      .then(res => setUsers(res))
      .then(res => setLoading(false))
      .catch(err => setErrors(true));
  }


  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className={style}>
      <Grid container spacing={0}>
        <Grid item sm={12} md={12} xl={12} xs={12}>
          <MaterialTable
            title={false}
            data={users}
            options={tableoptions}
            columns={[
              { title: 'clientId', field: 'clientId', hidden: true },
              {
                title: 'Client Name', field: 'clientname', render: (rowData) => (
                  <Link href={'/clientview/' + rowData.clientId}>{rowData.clientname}</Link>
                )
              },
              {
                title: 'Client Audit', field: 'clientname', render: (rowData) => (
                  <Link target="_blank" href={'/audit/' + rowData.clientId}>Audit</Link>
                )
              }
            ]}
          ></MaterialTable>
        </Grid>
      </Grid>


      <Snackbar open={hasError} autoHideDuration={6000} onClose={handleClose}  >
        <Alert onClose={handleClose} severity="error">
          The data can not be pulled at this time.
          <Button color="primary" onClick={window.location.reload}>Refresh</Button>
        </Alert>
      </Snackbar>

    </div>
  );
};

Clients.propTypes = {

};

export default Clients;
