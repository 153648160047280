import React from 'react';
import {style} from './index.scss';
import SignInSide from '../SignInSide';

const Login = (props) => {

    return (
        <div className={style}>
            <SignInSide></SignInSide>
        </div>
    );
};

Login.propTypes = {

};

export default Login;
