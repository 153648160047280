import { AppBar, Box, Button, Grid, Toolbar, Typography, Tab, Tabs, LinearProgress, useMediaQuery, Snackbar, ButtonGroup, IconButton } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Google from './google';
import StackAdapt from './stackadapt';
import Meta from './meta';
import Bing from './bing';
import Virtuous from '../../../virtuous/report';
import { FcPrint, FcShare, FcCalendar } from "react-icons/fc";
import Registrations from '../../../clients/registrations';
import { lightenHexColor, darkenHexColor, getCookie } from '../../../dwi-functions';
import _ from 'lodash';
import DateRangeSelector from "../../../clients/controls/date-range-selector";
import { useToken } from '../../../Login/TokenProvider';
import { useLocation } from 'react-router-dom';
import ReportSettings from '../../../settings/report-settings';
import Overview from '../monthly-report/overview';
import ClipboardJS from 'clipboard';
import { Alert } from '@material-ui/lab';
import { Redirect } from 'react-router-dom';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pl={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}





/**
 *Gets the ID needed for the look up, its the last section of the URL
 *
 * @param {*} thePath
 */



//console.log(id);

const DdMonthlyReport = (props) => {

    const [order, setorder] = useState([
        { overview: 'Overview' },
        { google_paid: 'Google Paid' },
        { google_grant: 'Google Grant' },
        { bing: 'Bing' },
        { meta: 'Meta' },
        { stackadapt: 'Display' },
        { virtuous: 'Virtuous' }
    ]);
    const location = useLocation();
    var id = location.pathname.split("/")[2];

    const searchParams = new URLSearchParams(location.search);
    const sdate = searchParams.get('sdate');
    const edate = searchParams.get('edate');
    const tab = (searchParams.get('tab') ? Number(searchParams.get('tab')) : 0)
    const [snack, setSnack] = useState({ msg: "", open: false });

    var _d = sdate ? new Date(sdate) : new Date();
    var _e = edate ? new Date(edate) : new Date(_d);

    // Set _e to 6 days before _d if edate is not provided
    if (!edate) {
        _d.setDate(_d.getDate() - 6);
        _e = (new Date());
    }

    const [selectedDateS, setSelectedDateS] = useState(_d.toISOString().split('T')[0]);
    const [selectedDateE, setSelectedDateE] = useState(_e.toISOString().split('T')[0]);


    var clipboard = new ClipboardJS('#urlCopy');

    clipboard.on('success', function (e) {
        setSnack({ msg: e.text + "  Copied!", open: true });
        e.clearSelection();
    });

    clipboard.on('error', function (e) {
        setSnack({ msg: e.action + "  Error!", open: true });
    });





    /*
    console.log('Start Date:', _d);
    console.log('End Date:', _e);
    console.log('Tab:', tab);
    */

    //const [selectedDateS, setSelectedDateS] = useState('2023-01-01');
    //const [selectedDateE, setSelectedDateE] = useState('2023-12-31');



    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            //  maxWidth: 1600,
            margin: 'auto'
        },
        data: {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(1)
        },
        cal: {
            position: 'absolute'
        },
        title: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '.75rem'
            }
        },
        logos: {
            width: '100%',

            padding: theme.spacing(2),
            borderRadius: theme.spacing(1),
            boxShadow: '0px 4px 8px rgba(93, 93, 93, .25)',
            [theme.breakpoints.up('xs')]: {
                maxWidth: 150
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        content: {
            marginTop: 0,
            [theme.breakpoints.up('sm')]: {
                marginTop: 0,
            },
        },
        sidebar: {
            [theme.breakpoints.up('sm')]: {
                minHeight: 'calc(100vh - 90px)'
            },
        },
        tabs: {
            "& .MuiTab-wrapper": {
                flexDirection: "row",
                justifyContent: "flex-start!important"
            }
        },
        dataMargin: {
            paddingLeft: 1,
            paddingRight: 1,
            [theme.breakpoints.up('xs')]: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1)
            },
        },
        selected: {
            // Define your active tab color here
            background: "linear-gradient(90deg,rgba(255,255,255,.5) 30%, #fff 100%)",
            color: '#000',
            fontSize: '101%'
        },
    }));


    const classes = useStyles();
    const [value, setValue] = useState(tab);
    const [client, setClient] = useState({});
    const [brandColor, setBrandColor] = useState({ "primary": "#4F2D7F", "secondary": "#FFD100" });
    const [reportProfile, setReportProfile] = useState();
    const [adsettings, setAdSettings] = useState();
    const [copyUrl, setcopyUrl] = useState(window.location.href + '/?sdate=' + selectedDateS + '&edate=' + selectedDateE + '&tab=' + value);
    const [tabs, setTabs] = useState({});
    const token = useToken();
    const admintoken = token.token;

    /*
        if () {
            setAdmin(true);
        }
    
    
        if (getCookie('_buta') === '59abdb0f-7e5a-4b1e-b8ce-c451091976de') {
            setAdmin(true);
       }  */
    //console.log(admintoken);


    const [open, setOpen] = useState(false);

    const toggle = () => setOpen(!open);



    const theme = createTheme({
        palette: {
            primary: {
                main: darkenHexColor(brandColor.primary, 0), // Assuming darkenHexColor returns a valid color
            },
            secondary: {
                main: darkenHexColor(brandColor.secondary, 0), // Assuming the same
            },
        },
        overrides: { // Use 'overrides' instead of 'components' for MUI v4
            MuiTab: {
                root: { // Target the root style
                    '&$selected': { // Use '&$selected' to increase specificity for selected tabs
                        backgroundColor: "#f7f7f7",
                        color: lightenHexColor(brandColor.secondary, 0),
                    },
                },
            },
            MuiOutlinedInput: {
                input: {
                    padding: '2px 14px',
                },
            },
        },
    });


    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));



    const handleRangeSelect = (rangeKey) => {
        setSelectedDateS(rangeKey.startDate.toISOString().split('T')[0]);
        setSelectedDateE(rangeKey.endDate.toISOString().split('T')[0]);
        setcopyUrl(window.location.href + '/?sdate=' + selectedDateS + '&edate=' + selectedDateE + '&tab=' + value)
        setOpen(!open);
    };

    const handlesnackClose = (e) => {
        setSnack({ msg: "", open: false });
    }

    const MyTabCreator = ({ field, form, ...props }) => {
        var l = JSON.parse(reportProfile.settings);
        var e = "";
        //  console.log('Start:',selectedDateS);
        //  console.log('End:',selectedDateE);
        switch (props.tab) {
            case "meta":
                e = <TabPanel value={value} index={props.posi}>
                    {(l.ad_sets[props.tab].id !== 0 ?
                        <Meta
                            title='Meta formerly Facebook'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.meta.id}
                            delta="15"
                            admintoken={admintoken}
                        />
                        : ''
                    )}
                </TabPanel>
                break;

            case "google_paid":

                e = <TabPanel value={value} index={props.posi}>
                    {(l.ad_sets['google_paid'].id !== 0 ?

                        <Google
                            onParentStateChange={handleDateChangeS}
                            title='Google Paid'
                            which="Paid"
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.google_paid.id}
                            delta="15"
                            type="SEARCH"
                            admintoken={admintoken}
                        />

                        : ''
                    )}
                </TabPanel>

                break;
            case "google_grant":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Google
                            onParentStateChange={handleDateChangeS}
                            title='Google Grant'
                            which="Grant"
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.google_grant.id}
                            delta="15"
                            type="SEARCH"
                            admintoken={admintoken}
                        />
                        : ''
                    )}
                </TabPanel>
                break;

            case "bing":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Bing
                            title='Bing'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.bing.id}
                            delta="15"
                            admintoken={admintoken}
                        />
                        : ''
                    )}
                </TabPanel>
                break;
            case "stackadapt":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <StackAdapt
                            title='Display'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.stackadapt.id}
                            delta="15"
                            admintoken={admintoken}

                        />
                        : ''
                    )}
                </TabPanel>
                break;

            case "virtuous":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Virtuous
                            title='Virtuous'
                            client={reportProfile}
                            themeColor={brandColor}
                            color={brandColor.primary}
                            sDate={selectedDateS}
                            eDate={selectedDateE}
                            advertiser_id={adsettings.stackadapt.id}
                            delta="15"
                            admintoken={admintoken}

                        />
                        : ''
                    )}
                </TabPanel>
                break;
            case "overview":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?

                        <Overview sdate={selectedDateS} admintoken={admintoken} clientid={id} edate={selectedDateE} themeColor={brandColor} ></Overview>
                        : ''
                    )}
                </TabPanel>
                break;
            case "donations":
                e = <TabPanel value={value} index={props.posi}>
                    {(adsettings ?
                        <Registrations admintoken={admintoken} sdate={selectedDateS} edate={selectedDateE} clientID={id} pageSize={1000} ></Registrations>
                        : ''
                    )}
                </TabPanel>
                break;




            default:
                break;
        }


        return (
            <div>{e}</div>
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDateChangeS = (date) => {
        var _d = date.toISOString().split('T')[0];
        //console.log(_d);
        setSelectedDateS(_d);
        fetchData();
    };





    async function fetchData() {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };



        const res2 = await fetch(appVars.api_url + "/tagmanager/" + id, requestOptions);
        res2
            .json()
            .then((res2) => {
                setClient(res2);
                  console.log(res2);
            })
            .catch((err) => {
                console.log(err);
            });






        const res3 = await fetch(appVars.api_url + "/reportprofile/" + id, requestOptions);
        res3
            .json()
            .then((res3) => {
                setBrandColor(JSON.parse(res3.color));
                setReportProfile(res3);

                setTabs(JSON.parse(res3.tabs));
                var collection = JSON.parse(res3.settings).ad_sets;
                const orderMap = order.reduce((acc, item, index) => {
                    const key = Object.keys(item)[0]; // Assuming each object has only one key-value pair
                    acc[key] = index;
                    return acc;
                }, {});
                // console.log(brandColor.primary);
                var re = _.fromPairs(
                    _.sortBy(_.toPairs(collection), ([key]) => orderMap[key] ?? Infinity)
                );


                setAdSettings(re);


            })
            .catch((err) => {
                console.log(err);
            });
    }


    useEffect(() => {
        if (!adsettings) {
            fetchData();
        }

    }, [props]);


    return (

        <div style={{ minHeight: '100vh' }}>
            <ThemeProvider theme={theme}>
                {(reportProfile ?
                    <div className={classes.root}>
                        <AppBar color="primary" position="relative" variant="outlined" style={{ padding: theme.spacing(1), backgroundColor: brandColor.primary }}>
                            <Toolbar>
                                <Grid container spacing={0} alignContent='space-between' alignItems='flex-start' justifyContent='center'>
                                    <Grid style={{ textAlign: "center", padding: theme.spacing(0) }} item xl={2} sm={3} xs={6} >
                                        <img className={classes.logos} style={{ maxWidth: 125, background: lightenHexColor(theme.palette.primary.main, 35) }} alt='' src={appVars.api_url + "/imageFiles/" + client.client + ".png"} />

                                    </Grid>
                                    <Grid item xl={6} sm={6} xs={12}  >

                                        <Grid container spacing={1} justifyContent='center' alignItems='center' >
                                            <Grid item xl={6} xs={12} style={{ color: lightenHexColor(brandColor.secondary, 0), background: lightenHexColor(brandColor.primary, 0), borderRadius: theme.shape.borderRadius, padding: theme.spacing(0) }}>
                                                <Typography className={classes.title} align="center" variant="h6" >{client.name}</Typography>
                                                <Typography align='center' variant="subtitle1">{new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(selectedDateS))} - {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(selectedDateE))}</Typography>
                                            </Grid>
                                            <Grid item xl={6} xs={12}>
                                                <Grid container spacing={0} style={{ justifyItems: 'stretch', alignItems: 'stretch', color: lightenHexColor(brandColor.primary, 0), background: lightenHexColor(brandColor.primary, 0), borderRadius: theme.shape.borderRadius, padding: theme.spacing(0) }}>

                                                    <Grid item style={{ justifyItems: 'stretch', alignItems: 'stretch', color: lightenHexColor(brandColor.primary, 0), background: lightenHexColor(brandColor.primary, 0), borderRadius: 0, padding: theme.spacing(0) }} xl={12} sm={6} xs={12}   >
                                                    </Grid>
                                                    <Grid item style={{ justifyItems: 'stretch', alignItems: 'stretch', color: lightenHexColor(brandColor.primary, 0), background: lightenHexColor(brandColor.primary, 80), borderRadius: theme.shape.borderRadius, padding: theme.spacing(0) }} xl={12} sm={6} xs={12}   >

                                                        <ButtonGroup color="primary" variant="contained" size="small" fullWidth >

                                                            <IconButton size='small' onClick={(e) => e.preventDefault()} color="primary">
                                                                <DateRangeSelector
                                                                    icon={true}
                                                                    open={open}
                                                                    initialDateRange={{
                                                                        startDate: _d,
                                                                        endDate: new Date()
                                                                    }}
                                                                    sDate={selectedDateS}
                                                                    eDate={selectedDateE}
                                                                    toggle={toggle}
                                                                    childToParent={handleRangeSelect}
                                                                    showOutsideDays
                                                                    fixedWeeks
                                                                />
                                                            </IconButton>
                                                            <IconButton size='small' onClick={(e) => e.preventDefault()} color="primary">
                                                                <FcShare id="urlCopy"
                                                                    data-clipboard-text={copyUrl}
                                                                />
                                                            </IconButton>
                                                            {(admintoken.token >= 10 ?
                                                                <IconButton size='small' onClick={(e) => e.preventDefault()} color="primary">
                                                                    <FcPrint />
                                                                </IconButton>
                                                                : '')}
                                                        </ButtonGroup>
                                                        <div className={classes.cal}>
                                                        </div>

                                                    </Grid>



                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ textAlign: "center", paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }} item xl={2} sm={3} xs={12} >

                                        <img style={{ maxWidth: 200, background: lightenHexColor(theme.palette.primary.main, 35) }} className={classes.logos} alt='' src={(id !== undefined ? appVars.api_url + '/imageFiles/' + id + '.png' : 'https://place-hold.it/60x60/aaa/000000/b8b8b8.jpg&text=R-LOGO')} />

                                    </Grid>
                                </Grid>

                            </Toolbar>
                        </AppBar>

                        <Grid container spacing={0} className={classes.content}>
                            <Grid item xl={1} sm={2} xs={12} style={{ background: lightenHexColor(brandColor.primary, 10) }} className={classes.sidebar} >
                                <AppBar position="relative" variant="outlined" style={{ border: 'none', width: '100%', background: lightenHexColor(brandColor.primary, 10), display: 'flex', justifyContent: 'flex-end' }}>
                                    {(tabs.tabs && adsettings ?
                                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Tabs style={{ width: '100%' }} className={classes.tabs} orientation={isDesktop ? 'vertical' : 'horizontal'} variant={isDesktop ? 'fullWidth' : 'scrollable'} scrollButtons="auto" value={value} onChange={handleChange} aria-label="simple tabs example">
                                                {Object.entries(_.pickBy(adsettings, (value, key) => { return value.id !== ""; })).map((tab, index) => {
                                                    var g = tab[1];
                                                    var foundObject = _.find(order, (obj) => _.has(obj, tab[0]));
                                                    // Get the value associated with the key, if the object is found
                                                    var foundValue = foundObject ? _.get(foundObject, tab[0]) : 'Key not found';
                                                    // console.log("Found:",foundValue);
                                                    var k = <Tab classes={{ selected: classes.selected }} style={{ justifyContent: 'flex-start', width: isDesktop ? '100%' : 'auto' }} label={foundValue} {...a11yProps(index)} />
                                                    return (
                                                        k
                                                    )
                                                })}


                                            </Tabs>
                                        </div>
                                        : <LinearProgress></LinearProgress>
                                    )}



                                </AppBar>
                            </Grid>
                            <Grid item xl={11} sm={10} xs={12} className={classes.data} >



                                {(tabs.tabs && adsettings ?
                                    <div className={classes.dataMargin}>
                                        {Object.entries(_.pickBy(adsettings, (value, key) => { return value.id !== ""; })).map((tab, index) => {
                                            var g = tab[1];
                                            //  console.log(g)
                                            var k = <MyTabCreator posi={index} tab={tab[0]}></MyTabCreator>
                                            return (
                                                k
                                            )
                                        })}


                                    </div>
                                    : ''
                                )}




                            </Grid>

                        </Grid>
                        {(admintoken.token >= 8 ?
                            <div style={{ padding: theme.spacing(1) }}>
                                <ReportSettings icon={true} themeColor={brandColor} title="Settings" client_id={id} />
                            </div>
                            : null)}
                    </div>
                    : '')}



                <div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlesnackClose}
                        open={snack.open}
                        color="success"
                        autoHideDuration={3000}
                        message={snack.msg}
                    />
                </div>
            </ThemeProvider>

        </div >


    );
};

DdMonthlyReport.propTypes = {

};

export default DdMonthlyReport;
