import React from 'react';


const Logout = ({}) => {

    return (
        <div className="">
            
        </div>
    );
};

Logout.propTypes = {

};

export default Logout;
