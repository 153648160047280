import React, { useState, useEffect } from 'react';
import { Typography, Card, CardHeader, Avatar, CardContent, useTheme, Grid, LinearProgress, Link } from '@material-ui/core';
import appVars from '../../appVars.json';


const ContactRep = (props) => {

    const [card, setcard] = useState([]);




    async function fetchData() {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        const res = await fetch(appVars.api_url + "/users/g/" + props.client.owner, requestOptions);
        res.json()
            .then((response) => setcard(response[0]))
            .catch((error) => console.error(error));
    }



    useEffect(() => {
        if (props.client) {
            fetchData();
        }
        return () => {

        };
    }, [props,card]);


    const theme = useTheme();

    //console.log(card);
    return (
        <Grid container alignContent='center' justifyContent='center' style={{ maxWidth: 600, margin: 'auto', height: '100vh' }}>
            <Grid item xs={12}>
                {(props.loading && card ?


                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title={
                                   <Typography align='center' title={props.client.name}>{props.client.clientname}</Typography>
                                }
                                 />
                            <CardContent>
                            <Avatar style={{width:100,height:100,margin:'auto'}} src={appVars.api_url + '/imageFiles/' + card.pkRecId + '.png'} />
                                <Typography align='center' style={{ padding: theme.spacing(3) }}>
                                  Please contact <Link href={'mailto:' + card.email}>{card.name}</Link><br/>  {props.client.name} license is invalid.<br /> License#: {card.pkRecId}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>





                    :
                    <div>
                        <img alt='' src={(props.profile ? appVars.api_url + "/imageFiles/" + props.profile.client + ".png" : '')} />
                        <Typography align='center' variant="h6">Checking License..</Typography>
                        <LinearProgress />
                    </div>
                )}

            </Grid>
        </Grid>
    );
};

ContactRep.propTypes = {

};

export default ContactRep;
