import { Button, Typography, Link, Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import React, { useState,useEffect } from 'react';
import { style } from './index.scss';
import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
import { makeStyles } from '@material-ui/core/styles';
import themeFile from '../../theme.json';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import appVars from '../../appVars.json';

const ConnectButton = (e) => {
    const { activateBrowserWallet, account, deactivate } = useEthers();
    const etherBalance = useEtherBalance(account);


    return account ? (
        <Card>
            <CardHeader style={{ backgroundColor: themeFile.palette.primary.main }} title={account &&
            `ID: ${account.slice(0, 6)}...${account.slice(
              account.length - 4,
              account.length
            )}`} titleTypographyProps={{variant:"body"}}></CardHeader>
            <CardContent>



               <Typography variant="subtitle2">Ethereum ${e.usd} <Link target="_BLANK" href={`https://etherscan.io/address/${account}`}><OpenInNewIcon title={account} fontSize="small"></OpenInNewIcon></Link></Typography>
                <Typography variant='body2'>
                    ETH: {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(4)} </Typography>
                <Typography variant='body2'>
                    USD: ${etherBalance && parseFloat(formatEther(etherBalance)*e.usd).toFixed(2)}
                </Typography>
            </CardContent>
            <CardActions color="primary">
                <Button variant="contained" color="secondary" fullWidth style={{ fontSize: '11px' }} onClick={(e) => {
                    deactivate();
                }}>Disconnect</Button>
            </CardActions>
        </Card>

    ) : (
        <Button
            fullWidth
            variant="contained"
            color="primary"
            data-connect="Wallet"

            onClick={(e) => {
                activateBrowserWallet();
                //                                                console.log(data);
                // setTimeout(() => { window.location = "/"; }, 1500);
            }}
        >Connect Wallet</Button>
    );

};

const useStyles = makeStyles((theme) => ({
    cardheader: {
        backgroundColor: '#fcfcfc'
    }
}));

const DappLogin = (props) => {
    const classes = useStyles();
    const [errors,setErrors] = useState();
    const [ethstats, setETHstats] = useState({
        "status": "1",
        "message": "OK",
        "result": {
            "ethbtc": "0.06116",
            "ethbtc_timestamp": "1624961308",
            "ethusd": "3385.53",
            "ethusd_timestamp": "1624961308",
        }
    });


    async function fetch_ethStats() {

        const res = await fetch('https://api.etherscan.io/api?module=stats&action=ethprice&apikey=' + appVars.ethapi);
        res
            .json()
            .then((res) => {
                setETHstats(res);
               // console.log(res);
                //  setLoading(false);
            })
            .catch((err) => setErrors(true));




    }

    useEffect(() => {
        fetch_ethStats();

     // setBalance(formatEther(etherBalance));
       // console.log(formatEther(balance));
    }, []);



    return (
        <div className={style}>


            <ConnectButton usd={ethstats.result.ethusd}></ConnectButton>



        </div>
    );
};

DappLogin.propTypes = {

};

export default DappLogin;
