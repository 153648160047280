import React, { useState, useEffect } from "react";

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
    Button,
    ButtonGroup,
    Snackbar,
    Toolbar,
    Avatar, IconButton, Link
} from "@material-ui/core";

import TableCard from "../../reports/table-card";
import Alert from "@material-ui/lab/Alert";
import ClipboardJS from "clipboard";
import appVars from '../../appVars.json';
import { Add, Edit, GridOffRounded, List } from "@material-ui/icons";
import _ from "lodash";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import ClientGridView from "../client-grid-view";
import Newclient from "../newclient";
import ReportSettings from "../../settings/report-settings";
import { BsMeta } from "react-icons/bs";
import { lightenHexColor } from "../../dwi-functions";




const SubClientList = (props) => {
    const location = useLocation();
    var id = location.pathname.split("/")[2];
    /*
    console.log('Start Date:', _d);
    console.log('End Date:', _e);
    console.log('Tab:', tab);
    */
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const admintoken = props.admintoken;
    const [brandColor, setBrandColor] = useState({ "primary": "#fff", "secondary": "#5791a0" });
    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [clientUsers, setClientUsers] = useState(false);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const [copySuccess, setCopySuccess] = useState("");
    // eslint-disable-next-line
    const [hideList, sethideList] = useState(true);
    // eslint-disable-next-line
    const [hideGrid, sethideGrid] = useState(false);




    const theme = createTheme({
        palette: {
            primary: {
                main: brandColor.primary, // Assuming darkenHexColor returns a valid color
            },
            secondary: {
                main: brandColor.secondary, // Assuming the same
            },
        },
        overrides: {
            MuiDialogTitle: {
                root: {
                    backgroundColor: brandColor.secondary,
                    color: brandColor.primary
                }
            },
            MuiOutlinedInput: {
                root: {
                   
                    '&$focused $notchedOutline': {
                        borderColor: brandColor.secondary, // Change this to your desired color
                    },
                },
                notchedOutline: { borderColor: brandColor.secondary,},
                focused: {},
            },
            MuiInputLabel: {
                outlined: {
                  '&$shrink': {
                    color: brandColor.secondary, // Change this to your desired color
                  },
                },
                shrink: {},
              },
              MuiStepIcon: {
                root: {
                  // Default color of the StepIcon
                  color: 'gray', 
                  '&$active': {
                    color: brandColor.secondary, // Change this to your desired active color
                  },
                  '&$completed': {
                    color: 'green', // Change this to your desired completed step color
                  },
                },
                active: {},
                completed: {},
              },
              MuiStepLabel: {
                label: {
                  '&$active': {
                    color: brandColor.secondary, // Change this to your desired active label color
                  },
                  '&$completed': {
                    color: 'green', // Change this to your desired completed label color
                  },
                },
                active: {},
                completed: {},
              }
        }
    });






    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors(false);
        setCopySuccessShow(false);
    };

    const handleToggle = (e) => {
        if (e.currentTarget.getAttribute('toggle') === 'list') {
            sethideGrid(true);
            sethideList(false);
        } else {
            sethideList(true);
            sethideGrid(false);
        }

    };




    async function fetchData() {
        const res = await fetch(appVars.api_url + "/tagmanager_detail/" + id);
        res
            .json()
            .then((res) => {
                var a = _.sortBy(res, ['name']);

                if (props.status) {
                    a = _.filter(a, function (o) { return o.status; });
                } else {
                    a = _.filter(a, function (o) { return !o.status; });
                }
                //console.log(a);
                setClientUsers(a);
                setLoading(false);
                //console.log(clientUsers);
            }).catch((err) => {
                console.log(err);
                setErrors(true)
            });




        const rp = await fetch(appVars.api_url + "/reportprofile/" + id, {
            method: 'GET',
            redirect: 'follow'
        });
        rp
            .json()
            .then((res3) => {

                if (res3 !== null) {
                    var colorsD = (typeof res3.color === 'object' && res3.color !== null ? res3.color : JSON.parse(res3.color));
                    res3.color = colorsD;
                }
                setBrandColor(colorsD);
                // console.log(colorsD);


            })
            .catch((err) => {
                console.log(err);
            });







    }

    useEffect(() => {


        if (clientUsers === false) {

            fetchData();
        }



        document.title = "Client Dashboard View"
        var clipboard = new ClipboardJS("#gtminstall");
        clipboard.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });

        var fbverification = new ClipboardJS("#fbverification");
        fbverification.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });



    });










    return (
        <ThemeProvider theme={theme}>
            <div style={{ margin: theme.spacing(1) }}>

                <Toolbar style={{ marginBottom: theme.spacing(1), padding: theme.spacing(.5) }} variant="regular" fullWidth>
                    <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">

                        {(admintoken.token >= 10 ? <Button size="medium" color="default" onClick={toggle /*"/client/new/?clientid=" + id*/} startIcon={<Add></Add>}>New Client</Button> : '')}
                        <Button size="medium" toggle="grid" color="default" onClick={handleToggle} startIcon={<GridOffRounded></GridOffRounded>}>Grid View</Button>
                        {(admintoken.token >= 10 ? <Button size="medium" toggle="list" color="default" onClick={handleToggle} startIcon={<List ></List>}>List View</Button> : '')}


                    </ButtonGroup>

                </Toolbar>
                {(admintoken.token >= 8 ?
                    <div style={{ padding: theme.spacing(0) }}>
                        <ReportSettings admintoken={admintoken} icon={false} fab={true} button={false} themeColor={brandColor} title="Settings" client_id={id} />
                    </div>
                    : null)}
                {(clientUsers ?
                    <div style={{ padding: theme.spacing(0) }}>


                        {(!hideGrid ?
                            <div>
                                <ClientGridView status={props.status} header={false} themeColor={brandColor} ></ClientGridView>
                            </div>
                            :

                            <TableCard

                                data={clientUsers}

                                options={{
                                    rowStyle: { fontSize: '13px', padding: 0, margin: 0 },
                                    cellStyle: {
                                        border: '1px solid ' + lightenHexColor(brandColor.primary, 0), textAlign: 'center',
                                        padding: theme.spacing(1)
                                    },
                                    grouping: false,
                                    searchFieldVariant: "outlined",
                                    exportButton: true,
                                    exportAllData: true,
                                    search: true,
                                    paging: (clientUsers.length > 5 ? true : false),
                                    pageSizeOptions: [10, 25, 50, 100],
                                    showTitle: false,
                                    toolbar: true,
                                    sort: true
                                }}
                                title="Campaigns"
                                count={(clientUsers.length > 5 ? 5 : clientUsers.length)}
                                client={props.client}
                                themeColor={brandColor}
                                columns={[
                                    { title: "pkRecId", field: "pkRecId", hidden: true },
                                    { title: "siteUrl", field: "siteUrl", hidden: true },


                                    {
                                        title: "", field: "name",
                                        render: (rowData) => (
                                            <IconButton size="small" color="secondary" href={"/subclientviewer/" + rowData.id}>
                                                <Edit></Edit>
                                            </IconButton>
                                        ),
                                    },
                                    {
                                        title: "owner", field: "owner",
                                        render: (rowData) => (

                                            <Avatar variant="circular" src={appVars.api_url + '/imageFiles/' + rowData.owner + '.png'} title={rowData.owner} ></Avatar>

                                        ),
                                    },
                                    {
                                        title: "Site", field: "site",
                                        render: (rowData) => (
                                            <div>
                                                <Link target="_BLANK" href={rowData.siteUrl}>
                                                    {rowData.name}
                                                </Link>

                                            </div>
                                        ),
                                    },
                                    //   {title: "GTM ID",field: "tag"},
                                    /*  {
                                          title: "Conversions", field: "fbconversions", render: (rowData) => (
                                              <Typography variant="body2">{rowData.fbconversions}</Typography>
                                          )
                                      },
                                      {
                                          title: "Revenue", field: "siteUrl", render: (rowData) => (
                                              <Typography variant="body2">{parseFloat(rowData.revenue).toLocaleString('en-US', {
                                                  style: 'currency',
                                                  currency: 'USD',
                                              })}</Typography>
                                          )
                                      },
                                             */
                                    {
                                        title: "Meta API?", field: "siteUrl", render: (rowData) => (
                                            (rowData.fbapitoken !== null
                                                ? <IconButton color="primary"><BsMeta></BsMeta></IconButton>
                                                : ''
                                            )

                                        )
                                    },
                                    /*      {
                                              title: "GTM Download", field: "tag", render: (rowData) => (
                                                  <IconButton color="secondary" href={'https://api.digitalwerksautomation.com:7325/generate-gtm-container/default/' + rowData.id} target="_BLANK"><BsDownload /></IconButton>
                
                                              )
                                          }, */
                                    // {title: "Site Type", field: "site" },
                                    {
                                        title: "GTM Instructions", field: "site",
                                        render: (rowData) => (
                                            <Button variant="outlined" color="secondary" href="" id="gtminstall" size="small" data-clipboard-text={appVars.lightwerksUrl + "gtm-install/" + rowData.id}>
                                                Copy
                                            </Button>
                                        ),
                                    },
                                ]}



                            />

                        )}
                    </div>
                    : '')}
                <Dialog
                    maxWidth="lg"
                    fullWidth
                    aria-labelledby="confirmation-dialog-title"
                    open={open}
                >
                    <DialogTitle id="confirmation-dialog-title">Add New Client</DialogTitle>
                    <DialogContent dividers>

                        <Grid container md={12} spacing={3}>



                            <Grid item md={12} >
                                <Newclient admintoken={admintoken} themeColor={brandColor} ></Newclient>

                            </Grid>

                        </Grid>





                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="outlined" color="primary" onClick={toggle} autoFocus >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>





                <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        The data can not be pulled at this time.
                        <Button color="primary" onClick={window.location.reload}>
                            Refresh
                        </Button>
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={copySuccessShow}
                    autoHideDuration={3000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity="success">
                        {copySuccess}
                    </Alert>
                </Snackbar>
            </div>
        </ThemeProvider>
    );
};

SubClientList.propTypes = {

};

export default SubClientList;
