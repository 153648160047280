import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field } from "formik";
import FileUploads from '../../settings/file-uploads';
import { v4 as uuidv4 } from 'uuid';
import { TextField, Button, Grid, Divider, Select, MenuItem } from "@material-ui/core";
import appVars from "../../appVars.json";
import ColorPicker from 'material-ui-color-picker';
import InputLabel from '@material-ui/core/InputLabel';

const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
var id = getLastItem(window.location.href);
//console.log(id);

const MyInput = ({ field, form, ...props }) => {
    return <TextField InputLabelProps={{ shrink: true }} {...field} {...props} />;
};

const FontSelect = ({ field, form, ...props }) => {
    var k = props['dataloop'];
    // console.log(k);
    return <Select placeholder="Which Tag Manager?" fullWidth size="small" {...field} {...props}>
        <MenuItem value="0">Select Font Family</MenuItem>
        {k.map((entry) => (
            <MenuItem key={entry.font_family} value={entry.font_family}>{entry.font_name}</MenuItem>
        ))}
    </Select>;
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },
    inputs: {
        marginBottom: theme.spacing(2),
        width: 'auto'
    },
    button: {
        marginRight: theme.spacing(1),
    },
    buttons: {
        position: "fixed",
        bottom: "calc(0px + 75px)",
        right: theme.spacing(2)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));




const ImageDetails = (props) => {
    const classes = useStyles();
    const [newid, setUUID] = useState((id ? id : uuidv4()));
    const [cid, setCID] = useState(id);
    const [fonts, setFonts] = useState([]);

    const childToParent = (childdata) => {
        details.imageName = newid + '.jpg';
        var f = appVars.api_url + '/imageFiles/' + details.imageName;
        const img = new Image();
        img.onload = function () {
            var SI = document.getElementById('superImage');
            SI.style.backgroundImage = "url(" + f + ")";
            SI.style.height = this.height + "px";
            SI.style.width = this.width + "px";

        }
        img.id = "imageBuilder";
        img.src = f;


        // SI.appendChild(IB);
        // IB.style.display = "none";

        var MSG = document.getElementById('message');
        MSG.innerHTML = "Sample Name";
        setdetails(details);
        updateMSG();

    };



    const updateMSG = () => {
        var MSG = document.getElementById('message');
        MSG.style.position = "relative";
        MSG.style.top = details.xOffset + "px";
        MSG.style.fontSize = details.fontSize + "px";
        MSG.style.textAlign = details.textAlign;
    }


    const [details, setdetails] = useState({
        "pkRecId": newid,
        "timeZone": "America\/New_York",
        "rgbColor": "[0,0,0]",
        "xOffset": "50",
        "yOffset": "50",
        "imageName": "",
        "fontSize": "46",
        "fontFamily": "Lato-Light.ttf",
        "clientId": cid,
        "targetDate": "2030-01-01 00:00:00",
        "direction": "image",
        "timeZoneId": "379",
        "timerDescription": "",
        "price": "0.0007",
        "defaultXOffset": "0",
        "textAlign": "center",
        "maxTextWidth": "600",
        "status": "1",
        "rtl": "0",
        "format": "%H   %I   %S"
    });



    async function loadFonts() {

        const res2 = await fetch(appVars.api_url + "/fonts");
        res2
            .json()
            .then((res2) => {
                setFonts(res2);
                //console.log(res2);
            })
    }

    async function fetchData() {
        console.log('Dedug');
        const res = await fetch(appVars.api_url + "/imager/get/" + newid);
        res
            .json()
            .then((res) => {
                if (res.length > 0) {
                    setdetails(res[0]);
                    console.log(details);
                }
            })




    }

    function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }

    function rgbToHex(r) {
        var x = JSON.parse(r);
        return "#" + componentToHex(x[0]) + componentToHex(x[1]) + componentToHex(x[2]);
    }

    //console.log(rgbToHex(details.rgbColor));
    const handleChange = (e) => {
        details[e.currentTarget.id] = e.currentTarget.value;
        setdetails(details);
        console.log(e.currentTarget.id);
    }


    const handleBlur = (e) => {
        console.log("Target Value ", e.currentTarget)
    }


    useEffect(() => {
        if (id) {
            fetchData();
        }
        loadFonts();
        //  console.log(f); 

    }, []);


    return (
        <div className={classes.root}>

            <Formik
                initialValues={details}
                enableReinitializ
                onSubmit={async values => {
                    await new Promise((resolve) => {


                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        //  values.clientId=this.props.clientId;
                        //  values['clientId'] = cid;
                        console.log(values);
                        var raw = JSON.stringify(values);


                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };
                        /*
                                                fetch(appVars.api_url2 + "/imager/add/", requestOptions)
                                                    .then(response => response.text())
                                                    .then(result => console.log(result))
                                                    .catch(error => console.log('error', error));*/
                        resolve();
                    });

                    alert(JSON.stringify(values, null, 2));
                }}
            >

                <Form id="detailsImage">

                    <Grid xl={12} container spacing={3} >
                        <Grid xl="2" item style={{ backgroundColor: "#efefef", height: "calc(100vh - 52px)", padding: "2em" }}>
                            <MyInput
                                id="clientId"
                                placeholder="Enter Image Description"

                                type="hidden"
                                fullWidth

                                value={cid}

                            />

                            <Field
                                id="timerDescription"
                                name="timerDescription"
                                placeholder="Enter Image Description"
                                label="Description"
                                value={details.timerDescription}
                                fullWidth
                                style={{ marginBottom: '2em' }}
                                component={MyInput}
                            />
                            <br />
                            <MyInput
                                id="fontSize"
                                placeholder="Enter Font Size"
                                label="Font Size"
                                type="number"
                                fullWidth
                                style={{ marginBottom: '2em' }}
                                // value={details.fontSize}
                                onChange={handleChange}
                                onBlur={handleBlur}

                            />
                            <br />
                            <InputLabel id="FontLabel">Select Font</InputLabel>
                            <FontSelect labelId="FontLabel" dataloop={fonts} id="fontFamily"
                                placeholder="Enter Font Family"
                                label="Font Family"
                                type="text"
                                fullWidth
                                style={{ marginBottom: '2em' }}
                                //  value={details.fontFamily}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            ></FontSelect>



                            <MyInput
                                id="textAlign"
                                placeholder="Text Alignment"
                                label="Text Aligment"
                                type="text"
                                fullWidth
                                style={{ marginBottom: '2em' }}

                                onChange={handleChange}
                                onBlur={handleBlur}

                            />

                            <ColorPicker
                                fullWidth
                                placeholder='Select Font Color'
                                value={rgbToHex(details.rgbColor)}
                            ></ColorPicker>
                            <Divider style={{ marginBottom: "2em", marginTop: "2em" }}></Divider>




                            <Button variant="outlined" color="primary" type="submit" >
                                Submit
                            </Button>





                        </Grid>
                        <Grid xl="10" item >
                            <Grid container xl={12}>

                                <Grid xl={4} >
                                    <MyInput
                                        id="xOffset"
                                        placeholder="Enter X Axis"
                                        label="Horizontal Position"
                                        type="number"
                                        fullWidth
                                        style={{ marginBottom: '2em' }}
                                        value={details.xOffset}
                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                    />

                                    <MyInput
                                        id="yOffset"
                                        placeholder="Enter Y Axis"
                                        label="Vertical Position"
                                        type="number"
                                        fullWidth
                                        style={{ marginBottom: '2em' }}
                                        value={details.yOffset}
                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                    />

                                    <MyInput
                                        id="defaultXOffset"
                                        placeholder="Enter Default X Offset"
                                        label="Default X Offset"
                                        type="number"
                                        fullWidth
                                        style={{ marginBottom: '2em' }}
                                        value={details.defaultXOffset}
                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                    />
                                    <br />
                                    <MyInput
                                        id="maxTextWidth"
                                        placeholder="Enter MAX Text Width"
                                        label="Max Text Width"
                                        type="number"
                                        fullWidth
                                        style={{ marginBottom: '2em' }}
                                        value={details.maxTextWidth}
                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                    />
                                    <br />


                                </Grid>
                                <Grid xl={8} style={{ paddingLeft: "1em" }}>

                                    <div id="superImage" style={{ width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center center" }}>
                                        <div id="message">{details.message}</div>

                                    </div>

                                    <FileUploads childToParent={childToParent} newid={details.pkRecId}></FileUploads>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                </Form>

            </Formik>
        </div>
    );
};

ImageDetails.propTypes = {

};

export default ImageDetails;
