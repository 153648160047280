import React from 'react';
import { style } from './index.scss';
import { Button, Grid, TextField } from '@material-ui/core';

const NewUser = (props) => {

    return (
        <div className={style}>
            <form action="">
                <Grid container md={12} spacing={3}>



                    <Grid item  md={12} >

                        <TextField
                            autoFocus
                            size='small'
                            margin="dense"
                            id="name"
                            label="User Name"
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={12}>
                        <TextField
                            autoFocus
                            size='small'
                            margin="dense"
                            id="email"
                            label="Email Address"
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={12}>


                        <TextField
                            margin="dense"
                            id="passwd"
                            label="Password"
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>


   



                </Grid>

            </form>
        </div>
    );
};

NewUser.propTypes = {

};

export default NewUser;
